import logo from './logo.svg';
import './App.css';

function App() {
  return (
   <>
  {/* Topbar Start */}
  <div className="container-fluid bg-light p-0">
    <div className="row gx-0 d-none d-lg-flex">
      <div className="col-lg-7 px-5 text-start">
        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
          <small className="fa fa-map-marker-alt text-primary me-2" />
          <small>
            Add: Rag. Office. 404 kokan Nagri Thkurpada Thane Mumbai
            Maharashtra.400612{" "}
          </small>
        </div>
        <div className="h-100 d-inline-flex align-items-center py-3">
          <small className="far fa-clock text-primary me-2" />
          <small>Mon - Sat : 09.00 AM - 09.00 PM</small>
        </div>
      </div>
      <div className="col-lg-5 px-5 text-end">
        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
          <i className="fa fa-whatsapp" aria-hidden="true" />
          <small className="fa fa-phone-alt text-primary me-2" />
          <small>+91-9172290724</small>
        </div>
        <div className="h-100 d-inline-flex align-items-center">
          <a
            className="btn btn-sm-square bg-white text-primary me-1"
            href="tel:+919172290724"
          >
            <i className="fab fa fa-phone" />
          </a>
          <a
            className="btn btn-sm-square bg-white text-primary me-1"
            href="https://www.facebook.com/medimodularindia?mibextid=ZbWKwL"
          >
            <i className="fab fa-facebook-f" />
          </a>
          <a
            className="btn btn-sm-square bg-white text-primary me-1"
            href="whatsapp://send?phone=+919172290724"
          >
            <i className="fab fa-whatsapp" />
          </a>
          <a
            className="btn btn-sm-square bg-white text-primary me-1"
            href="mailto:medimodularindia@email.com"
          >
            <i className="fab fa fa-envelope" />
          </a>
          <a
            className="btn btn-sm-square bg-white text-primary me-0"
            href="https://www.youtube.com/@MEDIMODULARINDIA"
          >
            <i className="fab fa-youtube" />
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}
  {/* Navbar Start */}
  <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
    <a
      href="index.html"
      className="navbar-brand d-flex align-items-center px-4 px-lg-5"
    >
      {/*<h2 class="m-0 text-primary">MEDI MODULAR INDIA</h2>*/}
      <img src="img/logo.jpg" height={75} alt="" />
    </a>
    <button
      type="button"
      className="navbar-toggler me-4"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav ms-auto p-4 p-lg-0">
        <a href="index.html" className="nav-item nav-link active">
          Home
        </a>
        <a href="#about" className="nav-item nav-link">
          About
        </a>
        <a href="#gallery" className="nav-item nav-link">
          Gallery
        </a>
        <a href="#service" className="nav-item nav-link">
          Service
        </a>
        <a href="#project" className="nav-item nav-link">
          Project
        </a>
        <a href="#team" className="nav-item nav-link">
          Team Members
        </a>
        <a href="#contact" className="nav-item nav-link">
          Contact
        </a>
        <a href="#client" className="nav-item nav-link">
          Client
        </a>
      </div>
    </div>
  </nav>
  {/* Navbar End */}
  {/* Carousel Start */}
  <div className="container-fluid p-0 pb-5">
    <div className="header-carousel position-relative">
      <div className="owl-carousel-item position-relative">
        <img className="img-fluid" src="img/20210807_130031.jpg" alt="" />
        <div
          className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
          style={{ background: "rgba(53, 53, 53, .7)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8 text-center">
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                  Welcome To{" "}
                </h5>
                <h1 className="display-3 text-white animated slideInDown mb-4">
                  {" "}
                  MEDI MODULAR INDIA{" "}
                </h1>
                <p className="fs-5 fw-medium text-white mb-4 pb-2">
                  MODULAR OPERATION THEATRE. CLEANROOM &amp; HVAC ENVIRONMENTAL
                  SOLUTION.
                </p>
                <a
                  href=""
                  className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                >
                  MODULAR OT
                </a>
                <a
                  href=""
                  className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                >
                  ANTIBACTERIAL PAINT{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Carousel End */}
  {/* Feature Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div
              className="d-flex align-items-center justify-content-center bg-light"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-user-check fa-2x text-primary" />
            </div>
            <h1 className="display-1 text-light mb-0">01</h1>
          </div>
          <h5>Creative Designers</h5>
        </div>
        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div
              className="d-flex align-items-center justify-content-center bg-light"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-check fa-2x text-primary" />
            </div>
            <h1 className="display-1 text-light mb-0">02</h1>
          </div>
          <h5>Quality Products</h5>
        </div>
        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div
              className="d-flex align-items-center justify-content-center bg-light"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-drafting-compass fa-2x text-primary" />
            </div>
            <h1 className="display-1 text-light mb-0">03</h1>
          </div>
          <h5>Free Consultation</h5>
        </div>
        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div
              className="d-flex align-items-center justify-content-center bg-light"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-headphones fa-2x text-primary" />
            </div>
            <h1 className="display-1 text-light mb-0">04</h1>
          </div>
          <h5>Customer Support</h5>
        </div>
      </div>
    </div>
  </div>
  {/* Feature Start */}
  {/* About Start */}
  <div
    className="container-fluid bg-light overflow-hidden my-5 px-lg-0"
    id="about"
  >
    <div className="container about px-lg-0">
      <div className="row g-0 mx-lg-0">
        <div className="col-lg-6 ps-lg-0" style={{ minHeight: 400 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute img-fluid w-100 h-100"
              src="img/IMG_1672484499146.jpg"
              style={{ objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>
        <div
          className="col-lg-6 about-text py-5 wow fadeIn"
          data-wow-delay="0.5s"
        >
          <div className="p-lg-5 pe-lg-0">
            <div className="section-title text-start">
              <h1 className="display-5 mb-4">About Us</h1>
            </div>
            <p className="mb-4 pb-2">
              MEDI MODULAR INDIA is a recognized leader in the design and
              manufacture of precision airflow control systems for use in
              critical room environments. Our customers include leading
              pharmaceutical companies, universities, hospitals, government
              research facilities, and industrial corporations. We offer
              innovative, technologically sound airflow and pressurization
              control solutions that combine unparalleled safety and performance
              with value and energy savings. You will find MEDI MODULAR INDIA
              Controls’ systems operating worldwide in laboratories, vivariums,
              hospital spaces, pharmaceutical process areas, clean rooms, open
              bench labs, and bio containment facilities.
            </p>
            <div className="row g-4 mb-4 pb-2">
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                    style={{ width: 60, height: 60 }}
                  >
                    <i className="fa fa-users fa-2x text-primary" />
                  </div>
                  <div className="ms-3">
                    <h2 className="text-primary mb-1" data-toggle="counter-up">
                      1234
                    </h2>
                    <p className="fw-medium mb-0">Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                    style={{ width: 60, height: 60 }}
                  >
                    <i className="fa fa-check fa-2x text-primary" />
                  </div>
                  <div className="ms-3">
                    <h2 className="text-primary mb-1" data-toggle="counter-up">
                      1234
                    </h2>
                    <p className="fw-medium mb-0">Projects Done</p>
                  </div>
                </div>
              </div>
            </div>
            <a href="" className="btn btn-primary py-3 px-5">
              Explore More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  {/*Gallery Start */}
  <div className="container-xxl py-5" id="gallery">
    <div className="container">
      <div className="section-title text-center">
        <h1 className="display-5 mb-5">Gallery</h1>
      </div>
      <div className="row g-4 portfolio-container">
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG_1672484975980.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG_1672484975980.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG_1672484998801.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG_1672484998801.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG_167248470704.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG_167248470704.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220129-WA0076.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220129-WA0076.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220303-WA0032.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220303-WA0032.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220327-WA0010.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220327-WA0010.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_16.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_16.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_17.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_17.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_18.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_18.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_19.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_19.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_20.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_20.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_21.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_21.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_14.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_14.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/Image_15.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image_15.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220129-WA0059.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220129-WA0059.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220129-WA0048.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220129-WA0048.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20221130-WA0003.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20221130-WA0003.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220303-WA0032.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220303-WA0032.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/20210808_135837.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/20210808_135837.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/20210808_142034.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/20210808_142034.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/20210814_142004.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/20210814_142004.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*Gallery End */}
  {/* Service Start */}
  <div className="container-xxl py-5" id="service">
    <div className="container">
      <div className="section-title text-center">
        <h1 className="display-5 mb-5">Our Services</h1>
      </div>
      <div className="row g-4">
        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item">
            <div className="overflow-hidden">
              <img className="img-fluid" src="img/20210808_135837.jpg" alt="" />
            </div>
            <div className="p-4 text-center border border-5 border-light border-top-0">
              <h4 className="mb-3">Laminar Air Flow </h4>
              <p>
                HVAC Laminar Air Flow Our laminar air flow systems help
                eliminate the contaminated dirt particles that usually float in
                the environment. We design our laminar air flow systems with
                such a precision that our clients can mount them to their
                ceiling with perfection. Our Ceiling mounted laminar air flow
                systems are easy to install.
              </p>
              <a className="fw-medium" href="">
                Read More
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item">
            <div className="overflow-hidden">
              <img className="img-fluid" src="img/20210808_142034.jpg" alt="" />
            </div>
            <div className="p-4 text-center border border-5 border-light border-top-0">
              <h4 className="mb-3">AHU</h4>
              <p>
                AHU Air Handling Unit (AHU) is a machine used to circulate air
                as part of a heating, ventilating, and air-conditioning system.
                An Air Handling Unit is usually a large metal box containing a
                blower along with motor arrangement, cooling and heating coils,
                different types of filter chambers, and volume control dampers.
                43mm Thermal Break Profile AHU 45mm Thermal Break Profile AHU
              </p>
              <a className="fw-medium" href="">
                Read More
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item">
            <div className="overflow-hidden">
              <img
                className="img-fluid"
                src="img/IMG-20220303-WA0032.jpg"
                alt=""
              />
            </div>
            <div className="p-4 text-center border border-5 border-light border-top-0">
              <h4 className="mb-3">MODULAR NICU</h4>
              <p>
                HEPA FILTER HOUSING HEPA Filter Housing, also called HEPA Box,
                Air Plenum Box, Air Inlet, is used to holing HEPA filter. For
                dierent type of air filters, the filter housing is dierent.
                These types of filter housing are available: HEPA Housing for
                Gasket Seal HEPA Filter, HEPA Housing for Gel Seal HEPA Filter
                with gel-filled side channel and HEPA Housing for Gel Seal HEPA
                Filter with gel-filled top channel.
              </p>
              <a className="fw-medium" href="">
                Read More
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item">
            <div className="overflow-hidden">
              <img
                className="img-fluid"
                src="img/IMG-20220129-WA0056.jpg"
                alt=""
              />
            </div>
            <div className="p-4 text-center border border-5 border-light border-top-0">
              <h4 className="mb-3">MODULAR OT PPGI </h4>
              <p>
                PPGI PUF panels are sandwiched panels made of polyurethane which
                is usually sandwiched between two metal sheets. Sandwich puf
                panels are widely used in steel structures in the present day
                due to their various advantages. PUF panels are also available
                in different sizes depending on the requirement and a PUF panel
                supplier or PUF panel manufacturer should be able to design them
                according to your specifications. A PUF panel’s core is made of
                polyurethane while the interior and exterior are made up of thin
                metal sheets. This gives them excellent heat and sound
                resistance and insulating properties. Due to these reasons, they
                are commonly used in the construction of various structures such
                as airports, exhibition halls, cold storages, auditoriums etc.
                PUF panels save energy costs while increasing the performance of
                a structure. The following are some of the advantages of
                sandwich puf panel sheets. .
              </p>
              <a className="fw-medium" href="">
                Read More
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item">
            <div className="overflow-hidden">
              <img
                className="img-fluid"
                src="img/IMG-20220129-WA0076.jpg"
                alt=""
              />
            </div>
            <div className="p-4 text-center border border-5 border-light border-top-0">
              <h4 className="mb-3">MODULAR OT SS304 </h4>
              <p>
                Stainless Steel 304 Wall &amp; Ceiling System Operation theatre
                walls and Ceiling made of 1 mm thick stainless steel sheets 304
                grade, visible side having satin/mat finish and coated with
                protective PVC taping, which shall be removed upon completion of
                the Operation theatre, before handing over. .
              </p>
              <a className="fw-medium" href="">
                Read More
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item">
            <div className="overflow-hidden">
              <img
                className="img-fluid"
                src="img/IMG-20230122-WA0031.jpg"
                alt=""
              />
            </div>
            <div className="p-4 text-center border border-5 border-light border-top-0">
              <h4 className="mb-3">ICU with HEPA Filter Housing, </h4>
              <p>
                HEPA, which stands for High Efficiency Particulate Air, is a
                designation used to describe filters that are able to trap 99.97
                percent of particles that are 0.3 microns. .
              </p>
              <a className="fw-medium" href="">
                Read More
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service End */}
  {/* Feature Start */}
  <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div className="container feature px-lg-0">
      <div className="row g-0 mx-lg-0">
        <div
          className="col-lg-6 feature-text py-5 wow fadeIn"
          data-wow-delay="0.5s"
        >
          <div className="p-lg-5 ps-lg-0">
            <div className="section-title text-start">
              <h1 className="display-5 mb-4">Why Choose Us</h1>
            </div>
            <p className="mb-4 pb-2">
              Today's client expects immediate and personalized results,MEDI
              MODULAR INDIA promises and intends to provide. The firm, which has
              a lot of experience and competence, provides exceptional medical
              services like a modular operation theatre set up that is carefully
              developed while keeping the latest technology and automation
              requirements in mind Today's world is gradually transitioning to
              automation, and the medical and healthcare sectors can
              tremendously profit from it. The organization has been able to
              embrace new techniques thanks to automated and prompt results and
              services. As a result, every product and service speaks for itself
              in terms of quality and exuberance The company MEDI MODULAR INDIA
              has been able to weather the test of superiority and create a huge
              clientele in a short period as the best modular OT manufacturer in
              India. With the best automation and technology in place, we make
              the optimum use of its resources, ensuring that every investment
              is worthwhile. Client-centric operations are crucial for the
              organization's excellence and supremacy So, if you've been
              considering transforming your OT and giving it a modular makeover,
              go no farther than the best suppliers. Here you can find all glass
              and ss modular ot manufacturer and supplier for your hospitals.
              The latter delivers the highest level of durability and quality in
              all of its products and services
            </p>
            <div className="row g-4">
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                    style={{ width: 60, height: 60 }}
                  >
                    <i className="fa fa-check fa-2x text-primary" />
                  </div>
                  <div className="ms-4">
                    <p className="mb-2">Quality</p>
                    <h5 className="mb-0">Services</h5>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                    style={{ width: 60, height: 60 }}
                  >
                    <i className="fa fa-user-check fa-2x text-primary" />
                  </div>
                  <div className="ms-4">
                    <p className="mb-2">Creative</p>
                    <h5 className="mb-0">Designers</h5>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                    style={{ width: 60, height: 60 }}
                  >
                    <i className="fa fa-drafting-compass fa-2x text-primary" />
                  </div>
                  <div className="ms-4">
                    <p className="mb-2">Free</p>
                    <h5 className="mb-0">Consultation</h5>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                    style={{ width: 60, height: 60 }}
                  >
                    <i className="fa fa-headphones fa-2x text-primary" />
                  </div>
                  <div className="ms-4">
                    <p className="mb-2">Customer</p>
                    <h5 className="mb-0">Support</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 pe-lg-0" style={{ minHeight: 400 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute img-fluid w-100 h-100"
              src="img/IMG_20240107_031205.jpg"
              style={{ objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Feature End */}
  {/* Projects Start */}
  <div className="container-xxl py-5" id="project">
    <div className="container">
      <div className="section-title text-center">
        <h1 className="display-5 mb-5">Our Projects</h1>
      </div>
      <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
        <div className="col-12 text-center">
          <ul className="list-inline mb-5" id="portfolio-flters">
            <li className="mx-2 active" data-filter="*">
              {" "}
            </li>
            <li className="mx-2" data-filter=".first">
              {" "}
            </li>
            <li className="mx-2" data-filter=".second">
              {" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="row g-4 portfolio-container">
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG_1672484707049.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG_1672484707049.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">MODULAR OT</p>
              <h5 className="lh-base mb-0">PPGI WALL PANEL</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220327-WA0010.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220327-WA0010.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2"> MODULAR OT</p>
              <h5 className="lh-base mb-0">GLASS WALL PANEL</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20220129-WA0076.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20220129-WA0076.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">MODULAR OT</p>
              <h5 className="lh-base mb-0">SS304 WALL PANEL</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20221130-WA0003.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20221130-WA0003.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">3D MODULAR OT </p>
              <h5 className="lh-base mb-0"> SS304&amp;GLASS WALL PANEL</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/20210808_142034.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/20210808_142034.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">AHU</p>
              <h5 className="lh-base mb-0">Air handling Unit</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG_1672485276162.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG_1672485276162.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">MODULAR OT</p>
              <h5 className="lh-base mb-0">PPGI WALL PANEL</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG-20231006-WA0015.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG-20231006-WA0015.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">MODULAR OT</p>
              <h5 className="lh-base mb-0">
                PPGI WALL PANEL ANTIBACTERIAL PAINT
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/IMAGE123.jpg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMAGE123.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">MODULAR OT</p>
              <h5 className="lh-base mb-0">PPGI WALL PANEL</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/IMG_1672484998801.jpg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/IMG_1672484998801.jpg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2">MODULAR OT</p>
              <h5 className="lh-base mb-0">PPGI WALL PANEL</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Projects End */}
  {/* Quote Start */}
  <div
    className="container-fluid bg-light overflow-hidden my-5 px-lg-0"
    id="contact"
  >
    <div className="container quote px-lg-0">
      <div className="row g-0 mx-lg-0">
        <div className="col-lg-6 ps-lg-0" style={{ minHeight: 400 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute img-fluid w-100 h-100"
              src="img/IMG_20220412_093434069.jpg"
              style={{ objectFit: "cover" }}
              alt=""
            />
          </div>
        </div>
        <div
          className="col-lg-6 quote-text py-5 wow fadeIn"
          data-wow-delay="0.5s"
        >
          <div className="p-lg-5 pe-lg-0">
            <div className="section-title text-start">
              <h1 className="display-5 mb-4">CONTACT</h1>
            </div>
            <p className="mb-4 pb-2">MEDI MODULAR INDIA</p>
            <form>
              <div className="row g-3">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Your Name"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="email"
                    className="form-control border-0"
                    placeholder="Your Email"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Your Mobile"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Hospital name"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12 col-sm-12">
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Location"
                    style={{ height: 55 }}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control border-0"
                    placeholder="Special Note"
                    defaultValue={""}
                  />
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <div className="h-100 d-inline-flex align-items-center">
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                href="tel:+919172290724"
              >
                <i className="fab fa fa-phone" />
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                href="https://www.facebook.com/medimodularindia?mibextid=ZbWKwL"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                href="whatsapp://send?phone=+919172290724"
              >
                <i className="fab fa-whatsapp" />
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-1"
                href="mailto:medimodularindia@email.com"
              >
                <i className="fab fa fa-envelope" />
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-0"
                href=""
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                className="btn btn-sm-square bg-white text-primary me-0"
                href="https://www.youtube.com/@MEDIMODULARINDIA"
              >
                <i className="fab fa-youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Quote End */}
  {/* Team Start */}
  <div className="container-xxl py-5" id="team">
    <div className="container">
      <div className="section-title text-center">
        <h1 className="display-5 mb-5">Team Members</h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img
                className="img-fluid"
                src="img/Image 2024-03-15.jpeg"
                alt=""
              />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">Founder &amp; CEO</h5>
              <small>MD SHAMSHAD ALAM</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/NEYAZ.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">HVAC ENGINEER</h5>
              <small>NEYAZ AHMAD </small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/sajjad.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">SITE ENGINEER</h5>
              <small>SAJJAD AHMAD</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/matin wakad.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">PROJECT HEAD</h5>
              <small>MATIN WADKAR</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/SARIB.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">SOFTWARE ENGINEER</h5>
              <small>SHARIB FAZAL</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/afjal.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">ELECTRICIAN</h5>
              <small>SURESH PATEL </small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/saquib.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">FABRICATOR</h5>
              <small>SAQUIB</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item">
            <div className="overflow-hidden position-relative">
              <img className="img-fluid" src="img/irfan.jpeg" alt="" />
              <div className="team-social">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="text-center border border-5 border-light border-top-0 p-4">
              <h5 className="mb-0">MARKETING HEAD </h5>
              <small>NITIN SETH </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Team End */}
  {/* CLIENT Start */}
  <div className="container-xxl py-5" id="client">
    <div className="container">
      <div className="section-title text-center">
        <h1 className="display-5 mb-5">Our CLIENT</h1>
      </div>
      <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
        <div className="col-12 text-center">
          <ul className="list-inline mb-5" id="portfolio-flters">
            <li className="mx-2 active" data-filter="*">
              {" "}
            </li>
            <li className="mx-2" data-filter=".first">
              {" "}
            </li>
            <li className="mx-2" data-filter=".second">
              {" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="row g-4 portfolio-container">
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/DR HANJI.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/DR HANJI.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                HANJI HOSPITAL YAMAKANMAEDI BELGAUM KARNATAKA
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/janki hps.jpeg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/janki hps.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">DR JITENDRA GUPTA KALYAN</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/Image mother care.jpeg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/Image mother care.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2"> </p>
              <h5 className="lh-base mb-0">MOTHER CARE HOSPITAL SHIRUR PUNE</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/VATSALYA.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/VATSALYA.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">VATSALYA HOSPITAL SHIRUR PUNE</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/INDRAVATI.jpeg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/INDRAVATI.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2"> </p>
              <h5 className="lh-base mb-0">
                {" "}
                INDRAVATI HOSPITAL AIROLI NAVI MUMBAI
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/MAHAVEER.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/MAHAVEER.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                SHREE MAHAVIR JAIN HOSPITAL THANE West Maharashtra
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img
                className="img-fluid w-100"
                src="img/CRYSTALCARE.jpeg"
                alt=""
              />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/CRYSTALCARE.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                DR AMOL GITTE CRYSTAL CARE HOSPITAL SHAHAPUR MUMBAI
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/shanti.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/shanti.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                DR KORANMATH EYE HOSPITAL ATHANI KARNATAKA
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/meena.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/meena.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                MEENA CRITICAR HOSPITAL GHANSOLI NAVI MUMBAI
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/manish.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/manish.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">MANISH HOSPITAL BHIWANDI</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/NIRAMAYA.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/NIRAMAYA.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">NIRAMAYA HOSPITAL CHINCHWAD PUNE</h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/HAKIMI.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/HAKIMI.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                HAKIMI HOSPITAL MALKAPUR BULDHANA
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/MALLIKA.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/MALLIKA.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                MALLIKA HOSPITAL JOGESHWARI West MUMBAI
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/PRADNYA.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/CRYSTALCARE.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                PRADNYA MATERNITY IVF &amp; MULTISPECIALITY HOSPITAL MULUND West
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="rounded overflow-hidden">
            <div className="position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/janki.jpeg" alt="" />
              <div className="portfolio-overlay">
                <a
                  className="btn btn-square btn-outline-light mx-1"
                  href="img/janki.jpeg"
                  data-lightbox="portfolio"
                >
                  <i className="fa fa-eye" />
                </a>
              </div>
            </div>
            <div className="border border-5 border-light border-top-0 p-4">
              <p className="text-primary fw-medium mb-2" />
              <h5 className="lh-base mb-0">
                JANKI GLOBAL MULTISPECIALITY HOSPITAL KALYAN
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* client End */}
  {/* Footer Start */}
  <div
    className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Address</h4>
          <p className="mb-2">
            <i className="fa fa-map-marker-alt me-3" />
            Rag. Office. 404 kokan Nagri Thkurpada Thane Mumbai
            Maharashtra.400612
          </p>
          <p className="mb-2">
            <i className="fa fa-phone-alt me-3" />
            +91 9172290724
          </p>
          <p className="mb-2">
            <i className="fa fa-envelope me-3" />
            medimodularindia@gmail.com
          </p>
          <div className="d-flex pt-2">
            <a className="btn btn-outline-light btn-social" href="">
              <i className="fab fa-twitter" />
            </a>
            <a
              className="btn btn-outline-light btn-social"
              href="https://www.facebook.com/medimodularindia?mibextid=ZbWKwL"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              className="btn btn-outline-light btn-social"
              href="https://www.youtube.com/@MEDIMODULARINDIA"
            >
              <i className="fab fa-youtube" />
            </a>
            <a className="btn btn-outline-light btn-social" href="">
              <i className="fab fa-linkedin-in" />
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Services</h4>
          <a className="btn btn-link" href="">
            HVAC Laminar Airflow Purification System
          </a>
          <a className="btn btn-link" href="">
            Modular Operation Theatre Project
          </a>
          <a className="btn btn-link" href="">
            Operation Theatre Control Panel
          </a>
          <a className="btn btn-link" href="">
            Vinyl Flooring
          </a>
          <a className="btn btn-link" href="">
            {" "}
            Air Tight Door
          </a>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Quick Links</h4>
          <a className="btn btn-link" href="#about">
            About
          </a>
          <a className="btn btn-link" href="#contact">
            Contact
          </a>
          <a className="btn btn-link" href="#Service">
            Service
          </a>
          <a className="btn btn-link" href="#project">
            Project
          </a>
          <a className="btn btn-link" href="#team member">
            Team Member
          </a>
          <a className="btn btn-link" href="#client">
            OUR CLIENT
          </a>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">BRANCH</h4>
          <p>MUMBAI, PUNE, KOLKATA, DELHI, BIHAR, KARNATAKA.</p>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="copyright">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            ©{" "}
            <a className="border-bottom" href="#">
              www.medimodularindia.in
            </a>
          </div>
          <div className="col-md-6 text-center text-md-end">
            {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
            Designed By{" "}
            <a className="border-bottom" href="https://htmlcodex.com">
              HTML Codex
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
  );
}

export default App;
